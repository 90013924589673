<template>
  <div>
    <ModalSendMail
      v-if="showSendMail"
      :showModal="showSendMail"
      :onClickCancel="hideModal"
      @reservationMailSent="getReservationMailLog"
    />

    <ui-section-header>
      <template v-slot:title>{{
        $t('Components.Reservation.ReservationMailLog.Main_Title')
      }}</template>
    </ui-section-header>

    <div
      class="actionBar has-background-white-ter has-padding has-margin-bottom"
    >
      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <a @click="setShowSendMail" class="has-icon">
            <span class="icon is-small">
              <font-awesome-icon :icon="['fas', 'paper-plane']" />
            </span>
            <span>{{
        $t('Components.Reservation.ReservationMailLog.Icon_SendMail')
      }}</span>
          </a>
        </div>
      </div>
    </div>

    <MailLog :mailLogs="mailLogs" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mailProvider from '@/providers/mail'

const MailLog = () => import('@/components/Mails/MailLog')
const ModalSendMail = () => import('@/components/Reservations/ModalSendMail')

export default {
  components: {
    MailLog,
    ModalSendMail,
  },

  data() {
    return {
      isLoading: false,
      mailLogs: [],
      showSendMail: false,
    }
  },

  computed: {
    ...mapState('reservationStore', ['reservation']),
  },

  created() {
    this.getReservationMailLog()
  },

  methods: {
    getReservationMailLog() {
      let self = this

      if (!self.isLoading) {
        self.isLoading = true

        mailProvider.methods
          .getReservationMailLog(self.reservation.Id)
          .then((response) => {
            if (response.status === 200) {
              self.mailLogs = response.data
            }
          })
          .finally(() => {
            self.isLoading = false
          })
      }
    },

    setShowSendMail() {
      this.showSendMail = true
    },

    hideModal() {
      this.showSendMail = false
    },
  },
}
</script>
